/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-16 09:45:01
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-17 16:21:14
 * @FilePath: \maintenancebg\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: () => import("../views/Login.vue") },
  { path: "/main", redirect: "/main/home" },

  {
    path: "/main",
    name: "Main",
    component: () => import("../views/HomeMain.vue"),
    children: [
      {
        path: "/main/home",
        name: "home",
        component: () => import("../views/HomeView.vue"),
      },
      {
        path: "/home/companydetail/:id",
        name: "company",
        component: () => import("../views/CompanyDetail.vue"),
      },
      {
        path: "/home/addcompany",
        name: "addcompany",
        component: () => import("../views/AddCompany.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === "/login") next();
  const tokenStr = window.localStorage.getItem("token");
  if (!tokenStr) next("/login");
  next();
});

export default router;
