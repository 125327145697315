<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
import { reactive, toRefs } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default {
  name: "App",
  setup() {
    const data = reactive({
      locale: zhCn,
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>
<style lang="scss" scoped></style>
